import React from 'react'

import Layout from '../../components/layout'
import { SmallTitlePage } from '../../components/common/smallTitlePage'
import { BeautyTreatments } from '../../components/common/beautyTreatments'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../../components/seo'
const TorunEsteticCosmetology = () => {

    const data = useStaticQuery(graphql`
    {
        allContentfulTorunKosmetologiaEstetyczna(sort: {fields: kolejnosc}) {
          nodes {
            id
            zdjecieGlowne {
                gatsbyImageData(layout: CONSTRAINED)
            }
            nazwaZabiegu
            opisZabiegu {
              opisZabiegu
            }
            slug
          }
        },

        file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)

  const treatments = data.allContentfulTorunKosmetologiaEstetyczna.nodes

    return (
        <Layout torun>
          <Seo
            title="Kosmetologia estetyczna - skuteczne zabiegi na twarz"
            description="Naturalne i nieinwazyjne zabiegi odmładzające i nawilżające skórę. Zapraszamy do kliniki BodyMed Group w Toruniu."
            url="https://bodymedgroup.pl/torun-kosmetologia-estetyczna"
          />
        <SmallTitlePage 
        title='Kosmetologia Estetyczna' 
        />
            <BeautyTreatments treatments={treatments}/>
        </Layout>
    )
}

export default TorunEsteticCosmetology
